declare global {
  interface Window {
    _env_: {
      REACT_APP_SERVER_URL: string | undefined;
      REACT_APP_CODES_PORTAL_URL: string | undefined;
      REACT_APP_GROUPS_PORTAL_URL: string | undefined;
      REACT_APP_DOMAIN: string | undefined;
      REACT_APP_NOTIFICATIONS_PORTAL_URL: string | undefined;
    };
  }
}

export const SERVER_URL =
  typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_SERVER_URL : process.env.REACT_APP_SERVER_URL;

export const CODES_PORTAL_URL =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_CODES_PORTAL_URL
    : process.env.REACT_APP_CODES_PORTAL_URL;

export const GROUPS_PORTAL_URL =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_GROUPS_PORTAL_URL
    : process.env.REACT_APP_GROUPS_PORTAL_URL;

export const NOTIFICATIONS_PORTAL_URL =
  typeof window !== 'undefined' && window._env_
    ? window._env_.REACT_APP_NOTIFICATIONS_PORTAL_URL
    : process.env.REACT_APP_NOTIFICATIONS_PORTAL_URL;

export const DOMAIN =
  typeof window !== 'undefined' && window._env_ ? window._env_.REACT_APP_DOMAIN : process.env.REACT_APP_DOMAIN;

export const THEME = undefined;
